import { z } from 'zod';

const OidcStateSchema = z
  .object({
    initialUrl: z.string().min(1).url(),
  })
  .strict();

type OidcState = z.infer<typeof OidcStateSchema>;

export class OidcStateManager {
  static fromState(rawState: unknown): OidcState {
    return OidcStateSchema.parse(rawState);
  }

  static getInitialState(): OidcState {
    return {
      initialUrl: window.location.href,
    };
  }
}
