import {
  OidcClientSettings,
  User,
  UserManager,
  UserManagerSettings,
  WebStorageStateStore,
} from 'oidc-client-ts';

import { OIDCAuthConfiguration } from '../../configuration/schemas/auth';
import { UserInfo } from '../../types';
import { AuthService } from '../auth.service';
import { OidcStateManager } from './state';

export class OidcService implements AuthService {
  static async create(
    configuration: OIDCAuthConfiguration,
  ): Promise<OidcService> {
    const oidcService = new OidcService(configuration.settings);
    await oidcService.checkInitialAuth();
    return oidcService;
  }

  userManager!: UserManager;

  private _accessToken: string | undefined;
  private _userProfile: UserInfo | undefined;

  get accessToken(): string {
    return this._accessToken!;
  }
  get userInfo(): UserInfo | undefined {
    return this._userProfile;
  }

  private constructor(configuration: OidcClientSettings) {
    this.setSettings(configuration);
    this.setupUrlRedirectHook();
  }

  private async login(): Promise<void> {
    await this.userManager.clearStaleState();
    return await this.userManager.signinRedirect({
      state: OidcStateManager.getInitialState(),
    });
  }

  public async logout(): Promise<void> {
    await this.userManager.clearStaleState();
    return await this.userManager.signoutRedirect();
  }

  private setSettings(configuration: OidcClientSettings): void {
    const settings: UserManagerSettings = {
      userStore: new WebStorageStateStore({
        prefix: 'oidc',
        store: window.localStorage,
      }),
      ...configuration,
    };

    this.userManager = new UserManager(settings);
    this.userManager.events.addUserLoaded(async () => {
      await this.checkAndRetrieveAccessToken();
    });
  }

  private setupUrlRedirectHook(): void {
    window.addEventListener(
      'popstate',
      (event: PopStateEvent & { singleSpa?: boolean }) => {
        if (event.singleSpa) {
          this.authenticate();
        }
      },
    );
  }

  private async checkInitialAuth(): Promise<void> {
    if (location.href.includes(this.userManager.settings.redirect_uri)) {
      await this.signinCallback();
    }

    return this.authenticate();
  }

  private async signinCallback(): Promise<void | User | null> {
    try {
      const user = await this.userManager.signinRedirectCallback();
      const state = OidcStateManager.fromState(user.state);
      // Avoid infinite loop
      if (!state.initialUrl.includes(this.userManager.settings.redirect_uri)) {
        window.location.href = state.initialUrl;
      }
    } catch (e) {
      console.error('[AuthService] The following error occurred ', e);
      return this.login();
    }
  }

  private async authenticate(): Promise<void> {
    const accessToken = await this.checkAndRetrieveAccessToken();
    if (!accessToken) {
      return this.login();
    }
  }

  private async checkAndRetrieveAccessToken(): Promise<string | undefined> {
    const user = await this.userManager.getUser();
    let accessToken;
    if (this.isUserAuthenticated(user)) {
      accessToken = user.access_token;
    }

    this._accessToken = accessToken;

    this._userProfile = user?.profile as UserInfo;

    return accessToken;
  }

  private isUserAuthenticated(user: User | null): user is User {
    return !!user && !user.expired;
  }
}
