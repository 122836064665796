import {
  Callback,
  Message,
  PublishOptions,
  PubSub,
  RegisterOptions,
  RetainedMessage,
  Subscription,
} from './types';

const hasKeyBy = <K>(set: Set<K>, predicate: (key: K) => boolean): boolean => {
  for (const key of set.keys()) {
    if (predicate(key)) {
      return true;
    }
  }
  return false;
};

const SCOPE_SEPARATOR = '.';
const WILDCARD = '*';

export class DockContext implements PubSub {
  private topicsToRetain: Set<string> = new Set();
  private registeredTopics: Set<string> = new Set();
  private topics: Map<string, Subscription[]> = new Map();
  private retainedMessages: Map<string, RetainedMessage> = new Map();

  register(topic: string, options?: RegisterOptions) {
    if (topic.includes(WILDCARD)) {
      console.warn('Topic is a wildcard');
      return;
    }

    if (!this.topics.has(topic)) {
      this.topics.set(topic, []);
      this.registeredTopics.add(topic);

      if (options?.retain) {
        this.topicsToRetain.add(topic);

        if ('initialValue' in options) {
          this.retainMessage(topic, options.initialValue, {
            retain: options.retain,
          });
        }
      }
    } else {
      console.warn('Topic has been registered already, possible conflicts');
    }
  }

  subscribe(topic: string, callback: Callback, once = false): () => void {
    if (!this.hasTopic(topic)) {
      console.warn('No topic registered');
      return () => undefined;
    }
    if (topic.includes(WILDCARD) && once) {
      console.warn('Subscription once for a wildcard is not supported');
      return () => undefined;
    }
    if (!this.topics.has(topic)) {
      this.topics.set(topic, []);
    }
    const subscription: Subscription = { callback, once };
    this.topics.get(topic)!.push(subscription);

    // Immediately deliver retained message if it exists for this topic
    this.retainedMessages.forEach(({ message }, retainedTopic) => {
      if (
        retainedTopic === topic ||
        this.matchesWildcard(topic, retainedTopic)
      ) {
        callback(message, retainedTopic);
      }
    });

    return () => this.unsubscribe(topic, callback);
  }

  publish(topic: string, message?: Message, options?: PublishOptions): void {
    // Retain the message if requested
    if (topic.includes(WILDCARD)) {
      console.warn('Cant publish to wildcard topic');
      return;
    }
    if (options?.retain) {
      if (!this.topicsToRetain.has(topic)) {
        console.warn('This topic is not registered to retain');
        return;
      }
      this.retainMessage(topic, message, options);
    }

    // Deliver the message to all subscribers of the topic
    const subscriptions = this.topics.get(topic);
    if (subscriptions) {
      subscriptions.forEach((subscription) => {
        subscription.callback(message, topic);
        if (subscription.once) {
          this.unsubscribe(topic, subscription.callback);
        }
      });
    }

    // Handle wildcard subscriptions
    this.topics.forEach((subs, subscribedTopic) => {
      if (this.matchesWildcard(subscribedTopic, topic)) {
        subs.forEach((sub) => sub.callback(message, subscribedTopic));
      }
    });
  }

  stats() {
    const stats = {
      topics: 0,
      topicsToRetain: 0,
      subscriptions: 0,
      retainedMessages: 0,
    };
    stats.topics = this.topics.size;
    stats.topicsToRetain = this.topics.size;
    this.topics.forEach(
      (subscriptions) => (stats.subscriptions += subscriptions.length),
    );
    stats.retainedMessages = this.retainedMessages.size;
    return stats;
  }
  unsubscribe(topic: string, callback: Callback): void {
    const subscriptions = this.topics.get(topic);
    if (subscriptions) {
      const index = subscriptions.findIndex(
        (subscription) => subscription.callback === callback,
      );
      if (index !== -1) {
        subscriptions.splice(index, 1);
      } else {
        console.warn('Cant find subscription to unsubscribe');
      }
    }
  }

  private matchesWildcard(
    subscribedTopic: string,
    publishedTopic: string,
  ): boolean {
    if (!subscribedTopic.includes(WILDCARD)) {
      return false;
    }
    let matches = true;
    const subscribedTopicParts = subscribedTopic.split(SCOPE_SEPARATOR);
    const publishedTopicParts = publishedTopic.split(SCOPE_SEPARATOR);

    for (let i = 0; i < publishedTopicParts.length; i++) {
      if (subscribedTopicParts[i] === WILDCARD) {
        continue;
      } else if (i < subscribedTopicParts.length) {
        matches = subscribedTopicParts[i] === publishedTopicParts[i];
      } else {
        matches = subscribedTopicParts.at(-1) === WILDCARD;
      }
    }

    return matches && subscribedTopicParts.length <= publishedTopicParts.length;
  }

  private hasTopic(topic: string): boolean {
    return (
      this.registeredTopics.has(topic) ||
      hasKeyBy(this.registeredTopics, (registeredTopic) =>
        this.matchesWildcard(topic, registeredTopic),
      )
    );
  }

  private retainMessage(
    topic: string,
    message: Message,
    options: PublishOptions,
  ) {
    if (this.registeredTopics.has(topic)) {
      this.retainedMessages.set(topic, { message, options });
    } else {
      console.log('Failed to retain message, no such topic registered');
    }
  }
}
