export class ThemeManager {
  private repository: Map<string, CSSStyleSheet> = new Map();
  private globalUrls: string[] = [];

  //TODO theme change mechanism

  async addCSS(url: string | string[], global = false) {
    const urls = this.prepareUrls(typeof url === 'string' ? [url] : url);
    await Promise.all(urls.map((url) => this.creatAdopted(url)));
    if (global && url.length) {
      this.globalUrls = [...this.globalUrls, ...urls];
    }
  }
  getStyles(urls: string[], includeGlobals = true): CSSStyleSheet[] {
    return [...(includeGlobals ? this.globalUrls : []), ...urls]
      .map((url) => {
        return this.repository.get(url);
      })
      .filter((stylesheet): stylesheet is CSSStyleSheet => !!stylesheet);
  }
  private prepareUrls(urls: string[]) {
    const uniqueStrings = [...new Set(urls)];
    return uniqueStrings.filter((url) => !(this.repository.has(url) || !url));
  }
  private async creatAdopted(url: string) {
    try {
      const constructedStylesheet = new CSSStyleSheet();
      const response = await fetch(url);
      const cssText = await response.text();
      await constructedStylesheet.replace(cssText);
      const rootIndex = Array.from(constructedStylesheet.rules || []).findIndex(
        (rule) => (rule as CSSStyleRule).selectorText === ':root',
      );
      if (rootIndex > -1) {
        constructedStylesheet.removeRule(rootIndex);
      }

      this.repository.set(url, constructedStylesheet);
    } catch (error) {
      console.error('Error fetching stylesheet', error);
    }
  }
}
